import React, { useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {
	Header,
} from '../../componentsRef'
import {Spinner} from '../../componentsRef/ui'
import {LogoutPopup} from '../LogoutPopup'
import TableauReport from 'tableau-react'



export const Dashboard = () => {
    const [tokenTableau, setTokenTableau] = useState()
	const { pagina } = useParams()
	const [LogoutState, setLogoutState] = useState(false)
    // PF/Promo/AnlisisROI
	let url_ = 'https://tableauserver-azure.pricemaker.io/t/Soprole/views/';
	let parameters_ = null;
	let nombresCadenas = [];
	let data = null;
	switch (pagina) {
		case 'amplitud':
			url_ = url_+'v3/AmplitudPromocional';
			break;
		case 'evaluacion':
    		url_ = url_+'atomizado/ROI';
			break;
		case 'historial':
			// url_ = url_+'atomizado/ROI';
			url_ = url_+'historial_soprole/atomizado';
			break;
		case 'planificacion':
			// url_ = url_+'atomizado/ROI';
			url_ = url_+'Planificacion_Promocional_soprole/PlanificacionPromocional';
			data = JSON.parse(localStorage.getItem('user_data'));
			nombresCadenas = data.user_data.cadenas.map(cadena => cadena.nombre.toUpperCase());
			// const resultadoCadenas = "Cadena="+nombresCadenas.join(', ');
			parameters_ = {
				"Cadena" : nombresCadenas
			}
			break;
		case 'panelmargenes':
			url_ = url_+'Panel_Margenes_Soprole_04-01-2021_BD/MargenxFamilia';
			break;
		case 'promotionalreview':
			url_ = url_+'Simulacion/PromotionalReview';
			break;
		case 'cierremes':
			url_ = url_+'Cierres_mes/Cierre';
			break;
		case 'panelactivacion':
			url_ = url_+'panel/PanelActivacion';
			break;
		case 'vistaproducto':
				url_ = url_+'VistaProducto/producto';
				break;
		default:
			url_ = url_+'atomizado/ROI';
			break;
	}
	/*
            parameters_ = {
			ID1: [dataProduct.code_product + '-' + data.cadena_details.name_customer],
		}
    */
	const fetchApi = async (tradicional = false) => {
		const url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		// https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		const dtargetSite = 'Soprole'
		const project = 'v3'
		const page = 'AmplitudPromocional'
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtarget_site: dtargetSite,
				project: project,
				page: page,
			}),
		})

		console.log(response.status)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

    useEffect(() => {
		fetchApi()
    }, [])

	return (
		<div className='HomeNew'>
			{LogoutState && <LogoutPopup setLogoutState={setLogoutState} />}
            <Header setLogoutState={setLogoutState} />
            {!tokenTableau ? (
								<Spinner />
							) : (
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: "100%",
										height: "95vh",
									}}
									query="?:jsdebug=n"
									parameters={nombresCadenas.length > 0 ? parameters_ : null}
								/>
							)}
		</div>
	)
}

